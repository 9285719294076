import React, { Component } from 'react';
import './Space.scss';

class Space extends Component {
    render() {
        let stars = [];
        for (let i = 0; i < 5; i++) {
            let bgposA = Math.floor(Math.random() * 100);
            let bgposB = Math.floor(Math.random() * 100);
            stars.push(
                <div
                    key={"stars-"+i}
                    className="stars"
                    style={{
                        backgroundPosition: bgposA+"% "+bgposB+"%",
                        animationDelay: i+"s"
                    }}
                />
            );
        }
        return (
            <div className="space">
                {stars}
            </div>
        );
    }
}

export default Space;
