import React, { Component } from 'react';
import {RenderAnimatedText} from '../AnimatedText/AnimatedText';
import './NodeMazeGenerator.scss';
const nmg = require('node-maze-generator');

class StableDiffusionD extends Component {
    constructor(props) {
        super(props);
        this.state = { maze: '' };

        // create refs for width, height and seed
        this.width = React.createRef();
        this.height = React.createRef();
        this.seed = React.createRef();

        // set default values to 49, 50 and 42
        this.width.current = 49;
        this.height.current = 50;
        this.seed.current = 42;

        this.Random = nmg.utils.Random;
        //this.Random.seed(42);

        this.initialize_generator();
    }

    do_seed = () => {
        this.Random.seed(this.seed.current)
    }

    componentDidMount() {
        this.generate();
    }

    initialize_generator = () => {
        this.generator = new nmg.generators.generator([
            {
                generator: nmg.generators.maze,
                options: {
                    width: this.width.current,
                    height: this.height.current,
                    floors: 1,
                }
            },
            {
                generator: nmg.generators.room,
                options: {
                    width: this.width.current,
                    height: this.height.current,
                    floors: 1,
                }
            }
        ]);
    }

    generate = () => {
        console.log("generate");
        this.do_seed();

        var rows = "";
        for (let z = 0; z < this.generator.data.grid.total_floors; z++) {
            console.log(`Floor ${z}`);
            for (let y = 0; y < this.generator.data.grid.height; y++) {
                let row = '';
                for (let x = 0; x < this.generator.data.grid.width; x++) {
                    let cell = this.generator.data.grid.cells[z][y][x];
                    let f = cell.blocked ? '\u2588' : '\u2591';
                    if (cell.stairs) {
                        if (cell.stairs.direction === 'up') {
                            f = '\u25B2';
                        }
                        else {
                            f = '\u25BC';
                        }
                    }
                    row += f;
                }
                rows += row + "\n";
            }
        }
        this.setState({
            maze: rows
        })
    }

    render() {
        return (
            <div>
                <div key="intro" className="intro">
                    <h2>
                        <RenderAnimatedText text="NodeJS Maze Generator" />
                    </h2>
                    <p>
                        Perfect mazes along with rooms generated using a custom implementation of the Growing Tree Algorithm.
                    </p>
                </div>
                <div className="grid">
                    <div>
                        <pre>
                            <code>
                                {this.state.maze}
                            </code>
                        </pre>
                    </div>
                    <div>
                        <p>
                            Generates a maze using the growing tree algorithm.
                        </p>
                        <p>
                            Refresh this page for a new maze.
                        </p>
                        <p>
                            Install with npm: <code>npm install node-maze-generator</code>
                        </p>
                        <p>
                            <a href={"https://github.com/w4ffl35/node-maze-generator"}>Github page</a>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default StableDiffusionD;
