import React, { Component } from 'react';
import {RenderAnimatedText} from '../AnimatedText/AnimatedText';
import './Projects.scss';

class Projects extends Component {
    render() {
        return (
            <section id="projects" className="cover pageContent projects">
                <div className="project">
                    Keyword transposition cipher
                </div>
                <div className="project">
                    Keyword transposition cipher
                </div>
                <div className="project">
                    Keyword transposition cipher
                </div>
                <div className="project">
                    Keyword transposition cipher
                </div>
                <div className="project">
                    Keyword transposition cipher
                </div>
                <div className="project">
                    Keyword transposition cipher
                </div>
                <h1 onClick={this.props.nextSection}>
                    <RenderAnimatedText text="Projects" />
                </h1>
            </section>
        );
    }
}

export default Projects;
