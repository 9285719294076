import React, { Component } from 'react';
import {RenderAnimatedText} from '../AnimatedText/AnimatedText';
import {Contact} from '../About/About'
import './JoeCurlee.scss';

class JoeCurlee extends Component {
    render() {
        return (
            <div>
                <div className="intro">
                    <h2>
                        <RenderAnimatedText text="joecurlee.com" />
                    </h2>
                    <p>A simple portfolio site which you are currently viewing.</p>
                </div>
                <div className="grid">
                    <div>
                        <p>
                            This site is built with React
                            {/*, ReactRouter, axios, steamapi, chart.js, moment and Sass.*/}
                            It is hosted on Github as a static site.
                        </p>
                        {/*<p>*/}
                        {/*    The contact form page (the same form on the right) uses*/}
                        {/*    axios to make a POST request to an API Gateway endpoint which*/}
                        {/*    triggers a lambda function that sends an email to me*/}
                        {/*    with your message.*/}
                        {/*</p>*/}
                        {/*<p>*/}
                        {/*    The steam stats page uses axios to make a GET request*/}
                        {/*    to an s3 bucket which contains a JSON data file filled*/}
                        {/*    with open stats on games I play and apps I use*/}
                        {/*    on steam. The file is compiled daily at midnight when a*/}
                        {/*    cron job triggers a lambda function which*/}
                        {/*    uses <a target="_blank" rel="noopener noreferrer" href="https://www.npmjs.com/package/steamapi">steamapi</a> to*/}
                        {/*    pull my stats from steam and store them in the*/}
                        {/*    previously mentioned JSON file.*/}
                        {/*</p>*/}
                    </div>
                    <div>
                        <h3>Contact</h3>
                        <Contact />
                    </div>
                </div>
            </div>
        );
    }
}

export default JoeCurlee;
