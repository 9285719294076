import React, { Component } from 'react';
import './AnimatedText.scss';

class RenderAnimatedText extends Component {
    render() {
        let anim = 0;
        return this.props.text.split('').map((item, index) => {
            anim = (anim === 1) ? 2 : (anim === 2) ? 3 : 1;
            return (
                <span
                    key={"character-"+index}
                    className={`anim-text-${anim}`}
                >
                    {item}
                </span>
            );
        })
    }
}

export {RenderAnimatedText};
