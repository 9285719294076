import React, { Component } from 'react';
import {RenderAnimatedText} from '../AnimatedText/AnimatedText';
import { Link } from 'react-router-dom'
import './Home.scss';

class Home extends Component {
    render() {
        return (
            <Link to="/about">
                <h1>
                    <RenderAnimatedText text="Curlee" />
                </h1>
            </Link>
        );
    }
}

export default Home;
