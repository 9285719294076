import React, { Component } from 'react';
import {RenderAnimatedText} from '../AnimatedText/AnimatedText';
import './KritaStableDiffusion.scss';
import image from './alkr8u7s.bmp';

class KritaStableDiffusion extends Component {
    render() {
        return (
            <div>
                <div key="intro" className="intro">
                    <h2>
                        <RenderAnimatedText text="Krita Stable Diffusion" />
                    </h2>
                    <p>
                        A plugin for the open source painting program Krita that allows you to use Stable Diffusion
                        directly within the program.
                    </p>
                </div>
                <div className="grid">
                    <div>
                        <p>
                            Stable Diffusion is a machine learning model and samplers which can be used to generate
                            images based on text prompts.
                        </p>
                        <p>
                            This is not the only Krita Stable Diffusion plugin however existing solutions are based on
                            the webui repository which is not suitable for a production environment due to license
                            restrictions and the limitations of its architecture.
                        </p>
                        <p>
                            This plugin aims to solve a number of problems which stem from
                            the webui approach taken by existing solutions. Namely those problems are:
                        </p>
                        <ul className="list">
                            <li>Image generation is blocking</li>
                            <li>Difficult to install all requirements</li>
                            <li>Occasional crashes due to memory errors etc.</li>
                        </ul>
                        <h2>
                            Blocking generation
                        </h2>
                        <p>
                            Threads were used in order to allow the generation of images and handling of requests and
                            responses asynchronously. This allows the user to continue working within Krita while
                            images are being generated.
                        </p>
                        <h2>
                            Socket server
                        </h2>
                        <p>
                            Rather than using a full-blow webserver, a low level socket server was created and used to
                            handle requests and responses. This allows for a smaller memory footprint and slightly
                            faster responses.
                        </p>
                        <h2>
                            Installation
                        </h2>
                        <p>
                            Currently the plugin can be downloaded from Github and installed by running a single command
                            which will unpack the plugin, move it to the correct directory, download the required models
                            and move everything to the appropriate location.
                        </p>
                        <h2>
                            Crashes
                        </h2>
                        <p>
                            Proper error handling was added to the server to recover gracefully from errors. This allows
                            the user to continue working within Krita without having to restart the plugin.
                        </p>
                    </div>
                    <div>
                        <a href={"https://github.com/w4ffl35/krita_stable_diffusion"}>
                            <img src={image} alt="Krita Stable Diffusion" />
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default KritaStableDiffusion;
