import React, { Component } from 'react';
import {RenderAnimatedText} from '../AnimatedText/AnimatedText';
import { Link } from 'react-router-dom'
import {KTC} from '../KTC/KTC';
import {KH} from '../KH/App';
import './Portfolio.scss';
import projects from './projectData.json';
import JoeCurlee from '../JoeCurlee/JoeCurlee';
import KritaStableDiffusion from '../KritaStableDiffusion/KritaStableDiffusion';
import StableDiffusionD from "../StableDiffusionD/StableDiffusionD";
import NodeMazeGenerator from '../NodeMazeGenerator/NodeMazeGenerator';
import AIRunner from '../AIRunner/airunner';

const projectComponents = {
    'ktc': KH,
    'joecurlee': JoeCurlee,
    'keyword-transposition-cipher': KTC,
    'kritastablediffusion': KritaStableDiffusion,
    'stablediffusiond': StableDiffusionD,
    'nodemazegenerator': NodeMazeGenerator,
    'airunner': AIRunner,
    'killhannah': KH
};

class Projects extends Component {
    componentWillUnmount() {
        var container = document.getElementById("gameContainer");
        if (container) container.parentNode.removeChild(container);
    }

    renderIntro(title, description) {
        return (
            <div className="intro">
                <h2>
                    <RenderAnimatedText text={title} />
                </h2>
                <p>{description}</p>
            </div>
        );
    }

    renderProject(key) {
        const ProjectComponent = projectComponents[key];
        if (!ProjectComponent) {
            return (
                <div>
                    {this.renderIntro(projects[key].title, projects[key].description)}
                    <div>
                        <iframe title="wild west poker" src="/WildWestPoker/index.html"  />
                    </div>
                </div>
            );
        }

        return (
            <div>
                {key === 'killhannah' ? (
                    <>
                        {this.renderIntro(projects[key].title, projects[key].description)}
                        <ProjectComponent />
                    </>
                ) : (
                    <ProjectComponent />
                )}
            </div>
        );
    }

    renderProjects() {
        return (
            <div>
                {this.renderIntro("Portfolio", "For more projects ake a look at my itch.io and github profiles for more projects and code.")}
                <div className="grid">
                    {Object.keys(projects).map((key, index) => {
                        return (
                            <Link key={`link-${index}`} to={`/portfolio/${key}`}>
                                <div>
                                    <h3>{projects[key].title}</h3>
                                    <p>{projects[key].technology}</p>
                                    <em>View more</em>
                                </div>
                            </Link>
                        );
                    })}
                </div>
            </div>
        );
    }

    render() {
        const { section } = this.props;
        return (
            <div>
                {!section ? this.renderProjects() : this.renderProject(section)}
            </div>
        );
    }
}

export default Projects;