import React, { Component } from 'react';
import pic from './me.png';
import { Link } from 'react-router-dom'
import {RenderAnimatedText} from '../AnimatedText/AnimatedText';
import './About.scss';
import data from './Data';
import axios from 'axios';

class About extends Component {
    renderPage() {
        let section = this.props.section||'bio';
        let title;
        let pageContent;
        switch(section) {
            case 'skills':
                title = 'Skills';
                pageContent = <Skills />;
                break;
            case 'contact':
                title = 'Contact';
                pageContent = <Contact />;
                break;
            default:
                title = 'Bio';
                pageContent = <Bio />;
                break;
        }
        return (
            <div>
                <div className="intro">
                    <h2>
                        <RenderAnimatedText text={title} />
                        <Link className={section === 'skills' ? 'selected' : ''} to="/about/skills">skills</Link>
                        <Link className={section === 'contact' ? 'selected' : ''} to="/about/contact">contact</Link>
                        <Link className={section === 'bio' ? 'selected' : ''} to="/about/bio">bio</Link>
                    </h2>
                    <p>
                        Experienced software engineer with a broad skill set, specializing in clean code, effective architecture, and user experience.
                    </p>
                </div>
                {pageContent}
            </div>
        );
    }

    render() {
        return (
            <div className="grid">
                <img src={pic} alt="Me at a party covered in strings" />
                {this.renderPage()}
            </div>
        );
    }
}

class Bio extends Component {
    render() {
        return (
            <div>
                <p>
                    I'm a software engineer passionate about clean code, smart architecture, and great user experiences. I taught myself everything I know about software development, and I love tackling challenging projects.
                </p>
                <p>
                    I've worked on all kinds of projects, from small apps to big websites, covering every part of the software development process from start to finish.
                </p>
                <p>
                    I live in Colorado with my family, where I work as a freelance software engineer. I also run Capsize Games, my own company, where I build applications that aim to make a difference.
                </p>
            </div>
        );
    }
}

class Contact extends Component {
    state = {email: '', subject: '', message: ''}

    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
          [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        axios.post(`https://8f76287sx2.execute-api.us-east-1.amazonaws.com/deployed/sendmail`, {
            email: this.state.email,
            subject: this.state.subject,
            message: this.state.message
        }).then((response) => {
            let state = this.state;
            state.completed = true;
            this.setState(state);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    renderForm() {
        return (
            // <form onSubmit={this.handleSubmit}>
            //     <input name="email" value={this.state.email} placeholder="email" onChange={this.handleInputChange} />
            //     <input name="subject" value={this.state.subject} placeholder="subject" onChange={this.handleInputChange} />
            //     <textarea name="message" value={this.state.message} placeholder="message" onChange={this.handleInputChange} />
            //     <button>send message</button>
            // </form>
            <p>Direct all inquiries to contact@capsizegames.com</p>
        );
    }

    renderFormCompleted() {
        return(
            <div>
                <p>Thank you, your message has been sent.</p>
            </div>
        );
    }

    render() {
        return (
            <div>
                {(this.state.completed) ? this.renderFormCompleted() : this.renderForm()}
            </div>
        )
    }
}

class Skills extends Component {
    render() {
        return (
            <div>
                <div className="skilllist">
                    {Object.keys(data.skills).map((k, ind) => (
                        <dl key={`list-${ind}`}>
                        <dt>{k}</dt>
                        {data.skills[k].map((skill, index) => (
                            <dd key={`item-${index}`}>{skill}</dd>
                        ))}
                        </dl>
                    ))}
                </div>
            </div>
        );
    }
}

export {About, Contact};
