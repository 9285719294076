import React, { Component } from 'react';
import { Link, Route, Routes } from 'react-router-dom'
import moment from 'moment';
import nhx from './kh_nhx.jpg';
import showData from './showData';
import './App.scss';

class KH extends Component {
    constructor(props) {
        super(props);
        this.renderPage = this.renderPage.bind(this);
    }

    renderPage(props) {
        const path = props.match.params.path;
        if (path === 'usshows') {
            return (
                <div className="grid">
                    <img src={nhx} alt="new heart for christmas 10" />
                    <div>
                        <h2>Final U.S. Shows</h2>
                        {showData.map((show, index) => {
                            return (
                                <div key={`show-${index}`}>
                                    <div className="show">
                                        <h3>{show.title}</h3>
                                        <p className="meta">{show.date} - {show.location}</p>
                                    </div>
                                    <div className="description">{show.description}</div>
                                    <div className="purchase">
                                        SOLD OUT
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }
        else {
            return <div className="content"><div className="logo" /></div>;
        }
    }

    render() {
        return (
            <div className="kh">
                <header>
                    <nav>
                        <Link to="/portfolio/killhannah/">Kill Hannah</Link>
                        <Link to="/portfolio/killhannah/usshows">Final US Shows</Link>
                        <a href="http://killhannah.myminto.com" target="_blank" rel="noopener noreferrer">Store</a>
                    </nav>
                </header>
                <Routes>
                    <Route
                        path="/:foo?/:bar?/:path?"
                        render={this.renderPage}
                    />
                </Routes>
                <footer>
                    &copy; {moment().format('YYYY')} All rights reserved
                </footer>
            </div>
        );
    }
}

export {KH};
