import React, { Component } from 'react';
import './KTC.scss';
import Form from '../Form/Form';
import {RenderAnimatedText} from '../AnimatedText/AnimatedText';
import {Cipher} from './jsktc';

class KTCDemo extends Component {
    state = { ktc: {}, encrypted: '', decrypted: ''}
    plainTextMessage = ''
    encryptedMessage = ''

    constructor(props) {
        super(props);
        this.handleKeyword = this.handleKeyword.bind(this);
    }

    handleKeyword(event) {
        const val = event.target.value;
        if (event.target.name === 'keyword') {
            this.setKTC(val);
        } else if (event.target.name === 'encrypt') {
            this.encrypt(val);
        } else if (event.target.name === 'decrypt') {
            this.decrypt(val);
        }
    }

    setKTC(val) {
        let state = this.state;
        state.ktc = new Cipher(val);
        if (this.plainTextMessage !== '') this.encrypt(this.plainTextMessage);
        if (this.encryptedMessage !== '') this.decrypt(this.encryptedMessage);
        this.setState(state);
    }

    encrypt(val) {
        let state = this.state;
        this.plainTextMessage = val;
        state.encrypted = state.ktc.encrypt(this.plainTextMessage);
        this.setState(state);
    }

    decrypt(val) {
        let state = this.state;
        this.encryptedMessage = val;
        state.decrypted = state.ktc.decrypt(this.encryptedMessage);
        this.setState(state);
    }

    render() {
        return (
            <div>
                <p key="paragraph">Start by entering a keyword</p>
                <div key="ktc" className="KTC">
                    <Form
                        data={[
                            {
                                label: 'Keyword',
                                name: 'keyword',
                                type: 'text'
                            },
                            {
                                label: 'Encrypt',
                                name: 'encrypt',
                                type: 'textarea'
                            },
                            {
                                label: 'Decrypt',
                                name: 'decrypt',
                                type: 'textarea'
                            }
                        ]}
                        handleChange={this.handleKeyword}
                    />
                    <div className="info">
                        <strong>keyword</strong>
                        <p>{this.state.ktc.keyword}</p>
                        <strong>cipher</strong>
                        <p>{this.state.ktc.cipher}</p>
                        <strong>alphabet</strong>
                        <p>{this.state.ktc.alpha}</p>
                        <strong>keyword alphabet</strong>
                        <p>{this.state.ktc.kwAlpha}</p>
                        <strong>encrypted alphabet</strong>
                        <p>{this.state.ktc.encryptedAlpha}</p>
                        <strong>encrypted message</strong>
                        <p>{this.state.encrypted}</p>
                        <strong>decrypted message</strong>
                        <p>{this.state.decrypted}</p>
                    </div>
                </div>
            </div>
        );
    }
}

class KTC extends Component {
    render() {
        return (
            <div>
                <div key="intro" className="intro">
                    <h2>
                        <RenderAnimatedText text="Keyword Transposition Cipher" />
                    </h2>
                    <p>
                        This project was based on the <a
                            href="https://hackerrank.com/challenges/keyword-transposition-cipher/problem"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Hacker Rank Keyword Transposition Cipher problem
                        </a>
                    </p>
                </div>
                <div className="grid">
                    <div>
                        <p>
                            This problem is lots of fun so I have found myself
                            revisiting it on multiple occasions. Thus far I have
                            solved it in three languages (Python, Javascript and
                            C#), released it as an NPM package, and built examples
                            in Unity and React most of which has been open sourced.
                        </p>
                        <p>As described by HackerRank - </p>
                        <blockquote cite="https://hackerrank.com/challenges/keyword-transposition-cipher/problem">
                            A keyword transposition cipher is a method of
                            choosing a monoalphabetic substitution to encode a
                            message. The substitution alphabet is determined by
                            choosing a keyword, arranging the remaining letters
                            of the alphabet in columns below the letters of the
                            keyword, and then reading back the columns in the
                            alphabetical order of the letters of the keyword.
                            <br />
                            <br />
                            <a
                                href="https://hackerrank.com/challenges/keyword-transposition-cipher/problem"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Read more on HackerRank.com
                            </a>
                        </blockquote>
                        <hr />
                        <ul>
                            <a
                                href="https://github.com/w4ffl35/react-keyword-transposition-cipher"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Demo source code available at Github
                            </a>
                            <a
                                href="https://www.npmjs.com/package/js-ktc"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                JS-KTC node package available on npm
                            </a>
                            <a
                                href="https://github.com/w4ffl35/js-ktc"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                JS-KTC source available on Github
                            </a>
                            <a
                                href="https://github.com/w4ffl35/CSharpKTC"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                C# KTC source available on Github
                            </a>
                        </ul>
                    </div>
                    <div>
                        <h3>Demo using JS-KTC</h3>
                        <KTCDemo />
                    </div>
                </div>
            </div>
        );
    }
}

export {KTC, KTCDemo};
