import React, { Component } from 'react';
import {RenderAnimatedText} from '../AnimatedText/AnimatedText';
import './Clients.scss';

class Clients extends Component {
    render() {
        return (
            <div>
                <div className="intro">
                    <h2>
                        <RenderAnimatedText text="Clients" />
                    </h2>
                    <p></p>
                </div>
                <div className="grid">

                </div>
            </div>
        );
    }
}

export default Clients;
