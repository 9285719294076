import React, { Component } from 'react';
import {RenderAnimatedText} from '../AnimatedText/AnimatedText';
import './airunner.scss';
import image from './screenshot.png';

class AIRunner extends Component {
    render() {
        return (
            <div>
                <div key="intro" className="intro">
                    <h2>
                        <RenderAnimatedText text="AI Runner" />
                    </h2>
                    <p>
                        An installable interface for Stable Diffusion and other machine learning models
                    </p>
                </div>
                <div className="grid">
                    <div>
                        <p>
                            AI Runner is an early access program which allows you to run AI models locally without an internet connection or subscription to a service.
                        </p>
                        <p>
                            Built with Python, PyQT6 pytorch and Huggingface libraries.
                        </p>
                        <p>
                            <a href={"https://capsizegames.itch.io/ai-runner"}>Product page</a>
                        </p>
                    </div>
                    <div>
                        <a href={"https://capsizegames.itch.io/ai-runner"}>
                            <img src={image} alt="AI Runner" />
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default AIRunner;
