import React, { Component } from 'react';
import {RenderAnimatedText} from '../AnimatedText/AnimatedText';
import './StableDiffusionD.scss';
import image from './qpnxka69.bmp';

class StableDiffusionD extends Component {
    render() {
        return (
            <div>
                <div key="intro" className="intro">
                    <h2>
                        <RenderAnimatedText text="stablediffusiond" />
                    </h2>
                    <p>
                    </p>
                </div>
                <div className="grid">
                    <div>
                        <p>
                            Stable Diffusion is a machine learning model and samplers which can be used to generate
                            images based on text prompts.
                        </p>
                    </div>
                    <div>
                        <a href={"https://github.com/w4ffl35/stablediffusiond/blob/master/resources/stablediffusiond_flowchart.png"}>
                            <img src={image} alt="stablediffusiond" />
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default StableDiffusionD;
