import React, { Component } from 'react';
import {RenderAnimatedText} from '../AnimatedText/AnimatedText';
import { Link } from 'react-router-dom'
import './Career.scss';
import jobs from './jobs.data.json';

class Career extends Component {
    renderJobs() {
        return(
            <div>
                <div className="intro">
                    <h2>
                        <RenderAnimatedText text="Career" />
                    </h2>
                    <p>
                      My career started as a junior developer at a small consulting firm, and since then,
                      I've worked with companies of all sizes. Here are some notable highlights from my journey.
                    </p>
                </div>
                <div className="grid">
                    {Object.keys(jobs).map((key, index) => {
                        return (
                            <Link key={`job-${index}`} to={`/career/${key}`}>
                                <div>
                                    <h3>{jobs[key].company} <span>{jobs[key].location}</span></h3>
                                    <p>
                                        {jobs[key].description}
                                    </p>
                                    <em>View more</em>
                                </div>
                            </Link>
                        );
                    })}
                </div>
            </div>
        );
    }

    renderJob(job) {
        if (!job) return null;
        return (
            <div>
                <div className="intro">
                    <h2>
                        <RenderAnimatedText text={jobs[job].company} />
                    </h2>
                    <p>
                        {jobs[job].title} <span>{jobs[job].start} &ndash; {jobs[job].end}</span>
                        <small>
                            {jobs[job].location}
                        </small>
                    </p>
                </div>
                <div className="details">
                    <div>
                        <div dangerouslySetInnerHTML={{__html: jobs[job].content}} />
                        <h2>Tasks</h2>
                        <ul>
                            {jobs[job].tasks.map((item, index) => {
                                return (
                                    <li key={`job-${index}`}>{item}</li>
                                );
                            })}
                        </ul>
                        {(jobs[job].achievements.length > 0) ? <h2>Achievements</h2> : ''}
                        <ul>
                            {jobs[job].achievements.map((item, index) => {
                                return (
                                    <li key={`job-${index}`}>{item}</li>
                                );
                            })}
                        </ul>
                        <h2>Technology</h2>
                        <p>{jobs[job].technology}</p>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                {(!this.props.section) ? this.renderJobs() : this.renderJob(this.props.section)}
            </div>
        );
    }
}

export default Career;
