import React, { Component } from 'react';
import './Stats.scss';
import axios from 'axios';
import moment from 'moment';
import { Chart } from 'chart.js';

class Stats extends Component {
    state = {
        steam: {
            summary: { avatar: {} },
            friends: [],
            games: [],
            badges: {},
            recent: []
        }
    }

    componentDidMount() {
        axios.get('/Data/steamData.json')
            .then(res => {
                let state = this.state;
                state.steam = res.data;
                let data = {labels: [], points: [], label: 'favorite games'};
                state.steam.games.map(item => {
                    if (data.labels.length < 10) {
                        data.labels.push(item.name);
                        data.points.push(Math.round((item.playTime/60) * 100) / 100);
                    }
                    return data;
                });
                this.renderChart(data);
                this.setState(state);
            })
            .catch(error => {});
    }

    renderChart(data) {
        var ctx = document.getElementById("myChart").getContext('2d');
        new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: data.labels,
                datasets: [{
                    label: data.label,
                    data: data.points,
                    backgroundColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                        'rgba(255, 59, 64, 1)',
                        'rgba(55, 19, 64, 1)',
                        'rgba(25, 159, 64, 1)',
                        'rgba(205, 19, 4, 1)'
                    ],
                    borderWidth: 0
                }]
            }
        });

    }

    render() {
        return (
            <div>
                <div>
                    <h2><i className="fab fa-steam-square"></i> Steam</h2>
                    <div className="grid maincontent">
                        <div>
                            <img alt="steam avatar" src={this.state.steam.summary.avatar.large} />
                            <div className="friends">
                                <h4>Friends</h4>
                                <div className="grid">
                                    {this.state.steam.friends.map((item, index) => {
                                        return (
                                            <div key={`friend-${index}`} className="friend">
                                                <a target="_blank" rel="noreferrer" href={item.data.url}><img alt="steam avatar" src={item.data.avatar.large} /></a>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div>
                            <h3>
                                <a target="_blank" rel="noreferrer" href={this.state.steam.summary.url}>{this.state.steam.summary.nickname}</a>
                                <small>Level {this.state.steam.level}</small>
                            </h3>
                            <ul>
                                <li>
                                    {moment.duration(this.state.steam.playtime, 'minutes').humanize()} played
                                </li>
                                <li>
                                    {this.state.steam.games.length} game{this.state.steam.games.length !== 1 ? 's' : ''} owned
                                </li>
                                <li>
                                    {this.state.steam.unplayed} unplayed game{this.state.steam.unplayed !== 1 ? 's' : ''}
                                </li>
                            </ul>
                            <div className="grid gameinfo">
                                <div>
                                    <h4>Most played</h4>
                                    <canvas id="myChart" width="100%" height="100%"></canvas>
                                </div>
                                <div className="games">
                                    <h4>Recently played</h4>
                                    <table>
                                        {this.state.steam.recent.map((item, index) => {
                                            return item.name ? (
                                                <tr key={`game-${index}`} className="game">
                                                    <td>{item.name}</td>
                                                    <td>{item.playTime2 > 0 ? moment.duration(item.playTime2, 'minutes').humanize() : 'None'}</td>
                                                </tr>
                                            ) : null;
                                        })}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Stats;
