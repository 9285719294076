import React, { Component } from 'react';
import './Form.scss';

class Form extends Component {
    state = {}

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        let state = this.state;
        state[event.target.name] = event.target.value;
        this.setState(state);
        if (this.props.handleChange) this.props.handleChange(event);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.callback(this.state);
        let state = this.state;
        Object.entries(this.state).map((item, i) => { return state[item[0]] = ''; });
        this.setState(state);
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                {this.props.data.map((item, index) => {
                        let formelement = null;
                        if (item.type === 'textarea') {
                            formelement = <textarea
                                              key={"textarea-"+index} type={item.type}
                                              name={item.name}
                                              value={this.state[item.name]||''}
                                              onChange={this.handleChange}
                                           />;
                        }
                        else if (item.type === 'text') {
                            formelement = <input
                                              key={"text-"+index} type={item.type}
                                              name={item.name}
                                              value={this.state[item.name]||''}
                                              onChange={this.handleChange}
                                          />
                        }
                        else if (item.type === 'submit') {
                            formelement = <input
                                              key={"submit-"+index}
                                              type={item.type}
                                              value={item.value}
                                          />
                        }
                        return (
                            <label key={"label-"+index} className={item.name}>
                                {item.label} {formelement}
                            </label>
                        );
                    })}
            </form>
        );
    }
}

export default Form;
